$(document).ready(() => {
	//popups
	$("body").on("click", ".open-popup", function (e) {
		e.preventDefault();
		if ($(this).attr("data-target")) $($(this).attr("data-target")).openPopup();
	});

	$("body").on("click", ".popup a.close", function (e) {
		e.preventDefault();
		$(this).closest(".popup").closePopup();
	});

	//tabs
	$("body").on("click", ".tabs[data-target] > *:not(.active)", function (e) {
		e.preventDefault();
		const tab_parent = $(this).closest(".tabs"),
			target = tab_parent.attr("data-target");
		if (tab_parent.length > 0 && target) {
			const tab = $(this),
				tabs = tab_parent.find(">*"),
				index = tabs.index(tab);

			$(target).each(function () {
				const content = $(this).find(">*");
				content.removeClass("active");
				content.eq(index).addClass("active");
			});

			tabs.removeClass("active");
			tab.addClass("active");
		}
	});

	$("form.validation").validationEngine({
		showPrompts: false,
		addFailureCssClassToField: "error",
		scroll: false,
		onFieldFailure: function (field) {
			if (field) {
				if (field.is(":radio")) {
					$(field).closest(".radio").addClass("error");
				}
			}
		},
		onFieldSuccess: function (field) {
			if (field) {
				if (field.is(":radio")) {
					$(field).closest(".radio").removeClass("error");
				}
			}
		},
	});
	$("form.validation [class*=validate]").on("change blur keyup", function () {
		$(this).validationEngine("validate");
	});

	$("body").on("click", "form.ajax button[type=submit]", function (e) {
		e.preventDefault();
		postFormAjax($(this).closest("form"));
		return false;
	});

	$("[data-mask]").each(function () {
		const type = $(this).data("mask");
		switch (type) {
			case "phone":
				$(this).inputmask({
					showMaskOnHover: false,
					mask: "(+994*) ###-##-##",
					definitions: {
						"#": {
							validator: "[0-9]",
							cardinality: 1,
							casing: "lower",
						},
						"*": {
							validator: "(10|50|51|55|60|70|77|99)",
							cardinality: 2,
							prevalidator: [{ validator: "[15679]", cardinality: 1 }],
						},
					},
				});
				break;
			case "number":
			case "decimal":
			case "integer":
				$(this).inputmask(type, { rightAlign: false });
				break;
		}
	});

	if ($("[data-body-class]").length > 0) {
		$("body").addClass($("[data-body-class]:first").attr("data-body-class"));
	}

	$("#header .search .toggle").on("click", function () {
		$("#search_popup").addClass("open");
		setTimeout(() => $("#search_popup").addClass("animate"), 10);
	});
	$("#search_popup .back").on("click", function () {
		$("#search_popup").removeClass("animate");
		setTimeout(() => $("#search_popup").removeClass("open"), 400);
	});

	if ($(".home-partners").length) {
		const homePartners = tns({
			container: ".home-partners .slider",
			items: 1,
			gutter: 15,
			edgePadding: 44,
			controlsText: ["", ""],
			nav: false,
			loop: false,
			responsive: {
				550: {
					items: 5,
				},
			},
		});
	}
	if ($(".news-detail .slider,.text-page:not(.project-details) .slider").length) {
		tns({
			container: ".news-detail .slider,.text-page .slider",
			items: 1,
			gutter: 30,
			controlsText: ["", ""],
			nav: false,
			loop: false,
			responsive: {
				550: {
					items: 3,
				},
			},
		});
	}
	if ($(".home-projects .slider").length) {
		tns({
			container: ".home-projects .slider",
			controlsText: ["", ""],
			nav: false,
			loop: false,
			controls: false,
			responsive: {
				0: {
					items: 1.5,
					gutter: 15,
				},
				"992": {
					items: 3,
					gutter: 30,
				},
			},
		});
	}

	if ($(".project-details .slider").length) {
		var ps = tns({
			container: ".text-page .slider",
			"items": 2,
			"center": true,
			"swipeAngle": false,
			items: 1,
			controlsText: ["", ""],
			nav: false,
			loop: false,
			responsive: {
				550: {
					items: 3,
				},
			},
		});
		$(".project-details .slider .tns-item:first").addClass('center');
		ps.events.on('indexChanged', function(info){
			$(".project-details .slider .center").removeClass('center');
			$(".project-details .slider .tns-item").eq(info.displayIndex - 1).addClass('center');
		});
	}

	if ($(".reports .tabs").length) {
		tns({
			container: ".reports .tabs",
			autoWidth: true,
			gutter: 0,
			controlsText: ["", ""],
			controls: false,
			nav: false,
			loop: false,
			mouseDrag: true,
			swipeAngle: false,
		});
	}
	if($(".catg-wrapper").length){
		$("body").addClass("categories");
	}
	
	$("[data-match-height]").each(function () {
		$(this).find($(this).attr("data-match-height")).matchHeight();
	});

	$(".media .image a").click(function (e) {
		e.stopPropagation();
	});

	if ($(".menu").length) {
		$(".menu .sub:has(ul) > a").click(function () {
			$(this).parent().toggleClass("active");
		});
		const path = location.pathname;
		if ($('.menu a[href*="' + path + '"]')) {
			const mp = $('.menu a[href*="' + path + '"]').parent();
			mp.addClass("active");
			if (!mp.parent().hasClass("menu"))
				mp.parent().parent().addClass("active");
		}
	}

	if ($(".vacancy").length) vacancy();

	$(window).scroll(function () {
		if ($(window).scrollTop() > 200) {
			$(".back-to-top").addClass("show");
		} else {
			$(".back-to-top").removeClass("show");
		}
	});
	$(".back-to-top").on("click", function (e) {
		e.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "200");
	});

	const media = window.matchMedia("(max-width: 991px)");
	mobileSupport(media);
	media.addListener(mobileSupport);

	if ($(".corp-structure").length > 0) {
		$(".corp-structure .item").each(function () {
			const item = $(this);
			const index = $(".corp-structure .item").index(item);
			const p = index + (2 - (index % 3));
			let elem = $(".corp-structure .item").eq(p);
			const child = item.find(".dd");
			if (!$.is_mobile) {
				if (!elem.length) elem = $(".corp-structure .item").last();

				if (!$(".group_" + p + "_row").length)
					$(`<div class="col-md-12 group_${p}_row"></div>`).insertAfter(elem);
				item.addClass("group_parent_" + p);
				child.addClass("group_" + p).appendTo(".group_" + p + "_row");
			}

			item.find(".title").click(function () {
				if (item.hasClass("active")) {
					child.removeClass("active");
					item.removeClass("active");
				} else {
					if (!$.is_mobile) {
						const other = $(".group_" + p + ".active").not(child);
						if (other.length > 0) {
							other.removeClass("active");
							$(".group_parent_" + p)
								.not(item)
								.removeClass("active");
						}
					}
					child.addClass("active");
					item.addClass("active");
				}
			});
		});
	}
	if ($(".home-graph").length > 0) {
		$(".home-graph .count").each(function () {
			const id = uniqueId();
			const val = $(this).text() * 1;
			$(this).attr("id", id);
			const countUp = new CountUp(this, 0, val, 0, 2);
			$(this).data("countup", countUp);
		});
		$(window).on("resize scroll", function () {
			if ($(".home-graph").isInViewport()) {
				$(".home-graph .count").each(function () {
					$(this).data("countup").start();
				});
			}
		});
	}
});
(function ($) {
	$.fn.countup = function (params) {
		// make sure dependency is present
		if (typeof CountUp !== "function") {
			console.error(
				"countUp.js is a required dependency of countUp-jquery.js."
			);
			return;
		}

		var defaults = {
			startVal: 0,
			decimals: 0,
			duration: 2,
		};

		if (typeof params === "number") {
			defaults.endVal = params;
		} else if (typeof params === "object") {
			$.extend(defaults, params);
		} else {
			console.error(
				"countUp-jquery requires its argument to be either an object or number"
			);
			return;
		}

		this.each(function (i, elem) {
			var countUp = new CountUp(
				elem,
				defaults.startVal,
				defaults.endVal,
				defaults.decimals,
				defaults.duration,
				defaults.options
			);

			countUp.start();
		});

		return this;
	};
})(jQuery);
$.fn.isInViewport = function () {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
};
window.unique_id = 0;
function uniqueId() {
	window.unique_id++;
	return "asgUniqueId_" + window.unique_id;
}
$.is_mobile = false;
const mobileSupport = (media) => {
	if (media.matches) {
		$.is_mobile = true;
		if (!$(".mobile-menu nav").length) {
			$("#header .lang").clone().appendTo(".mobile-menu .top");
			$("#header nav").clone().appendTo(".mobile-menu");
			$(".mobile-menu nav > ul > li:has(ul) a").click(function () {
				$(this).parent().toggleClass("active");
				$(".mobile-menu").toggleClass("nav_open");
			});
			$(".mobile-menu .close").click(function (e) {
				e.preventDefault();
				$(".mobile-menu nav li.active").removeClass("active");
				$(".mobile-menu").removeClass("nav_open active");
				setTimeout(() => $(".mobile-menu").removeClass("animate"), 400);
				$("body").removeClass("disable_scroll");
			});
			$(".open-mobile-menu").click(function () {
				$(".mobile-menu").toggleClass("animate");
				setTimeout(() => $(".mobile-menu").toggleClass("active"), 10);
				$("body").addClass("disable_scroll");
			});
		}

		if ($(".media .tabs").length) {
			window.mediaTabs = tns({
				container: ".media .tabs",
				autoWidth: true,
				gutter: 0,
				controlsText: ["", ""],
				controls: false,
				nav: false,
				loop: false,
				mouseDrag: true,
				swipeAngle: false,
			});
		}

		$("[data-mobile-pos]").each(function () {
			const pos = $(this).attr("data-mobile-pos").split(",");
			const elem = $(this)
				.clone()
				.removeClass("hidden-xs")
				.removeAttr("data-mobile-pos")
				.addClass("cfm");
			if (pos[0] == "after") elem.insertAfter(pos[1]);
			else elem.insertBefore(pos[1]);
		});

		$(document).trigger("mobile_on");
	} else {
		$.is_mobile = false;

		if ($(".media .tabs").length && !!window.mediaTabs) {
			window.mediaTabs.destroy();
			window.mediaTabs = null;
		}
		$(".cfm").remove();
		$(document).trigger("mobile_off");
	}
};
$.fn.openPopup = function () {
	const elem = $(this);
	if (elem.length > 0) {
		const opened_popup = $(".popup").filter(".show");

		if (opened_popup.length) opened_popup.closePopup();
		$("body").addClass("disable_scroll");
		elem.addClass("show");
		setTimeout(() => elem.addClass("animate"), 1);
		elem.trigger("openPopup");
	}
};
$.fn.closePopup = function () {
	const elem = $(this);
	if (elem.length > 0) {
		elem.removeClass("animate");
		setTimeout(() => elem.removeClass("show"), 400);
		elem.trigger("closePopup");
		$("body").removeClass("disable_scroll");
	}
};

function vacancy() {
	const attrs = {},
		button = $(".vacancy .upload .select-file");
	$.each(button[0].attributes, function (index, attribute) {
		attrs[attribute.name] = attribute.value;
	});

	/* file uploader */
	$(".vacancy .upload")
		.fineUploader({
			//debug:true,
			uploaderType: "basic",
			autoUpload: false,
			multiple: false,
			button: button,
			request: {
				endpoint: attrs["data-url"],
				inputName: attrs["data-input-name"],
			},
			validation: {
				itemLimit: attrs["data-max-files"],
				allowedExtensions: ["pdf", "doc", "docx"],
				sizeLimit: 1024 * 1024 * attrs["data-size-limit"],
			},
			messages: {
				typeError: attrs["data-type-error"],
				sizeError: attrs["data-size-error"],
			},
		})
		.on("error", function (event, id, name, reason) {
			if (id === null) alertify.error(reason);
		})
		.on("complete", function (event, id, name, responseJSON) {
			if (responseJSON.code === 200) {
				$(".vacancy .success").removeClass("hide");
				$(".vacancy .file-preview,.vacancy .submit").addClass("hide");
			} else if (!!responseJSON.message) alertify.error(responseJSON.message);
		})
		.on("submit", function (elem, id, name) {
			button.addClass("hide");
			$(".vacancy .file-preview .name").html(name);
			$(".vacancy .file-preview").removeClass("hide");
			$(".vacancy .submit").removeClass("hide");
		});
	$("body").on("click", ".vacancy .file-preview .delete", function () {
		$(".vacancy .upload").fineUploader("reset");
		button.removeClass("hide");
		$(".vacancy .file-preview .name").html("");
		$(".vacancy .file-preview").addClass("hide");
		$(".vacancy .submit").addClass("hide");
	});
	$("body").on("click", ".vacancy .submit", function () {
		$(".vacancy .upload").fineUploader("uploadStoredFiles");
	});
}

function postFormAjax(form) {
	if (form.validationEngine("validate") && form.attr("request") != 1) {
		let url = form.attr("action");

		const disabled = [];

		form.find(":disabled").each(function () {
			disabled.push(this);
			$(this).prop("disabled", false).removeProp("disabled");
		});
		let data = form.serialize();

		$.each(disabled, function () {
			$(this).prop("disabled", true);
		});

		$.ajax({
			url: url,
			type: "POST",
			dataType: "json",
			cache: false,
			data: data,
			beforeSend: function () {
				form.attr("request", 1);
				if (
					form.css("position") != "absolute" &&
					form.css("position") != "relative"
				)
					form.css("position", "relative");
				$(
					'<div class="loading"><div class="lds-ripple"><div></div><div></div></div></div>'
				).appendTo(form);
			},
			success: function (data) {
				form.attr("request", 0);
				if (data.code == 200) {
					form.addClass("success");
					form.find('button[type="submit"]').prop("disabled", true);
				} else if (typeof data.message != "undefined") {
					alertify.error(data.message);
				}
			},
			error: function (x, e) {
				form.attr("request", 0);
				var e_msg = "";
				if (x.status == 0)
					e_msg = "You are offline!!<br> Please Check Your Network.";
				else if (x.status == 404) e_msg = "Requested URL not found.";
				else if (x.status == 500)
					e_msg = "Internel Server Error." + x.responseText;
				else if (e == "parsererror")
					e_msg = "Error.<br>Parsing JSON Request failed.";
				else if (e == "timeout") e_msg = "Request Time out.";
				else if (e == "error") e_msg = "Request error.";
				else if (e == "abort") e_msg = "Request abort.";
				else e_msg = "Unknow Error.<br>" + x.responseText;
			},
			complete: function () {
				$(".loading").remove();
			},
		});
	}
}
